import { RSOProvider } from "@riotgames/riotpay-utils";
import { IntlProvider } from "react-intl";

import App from "./App";

type RootProps = {
  locale: string;
  messages: Record<string, string>;
};

const Root = ({ locale, messages }: RootProps): JSX.Element => {
  return (
    <RSOProvider>
      <IntlProvider locale={locale} messages={messages}>
        <App />
      </IntlProvider>
    </RSOProvider>
  );
};

export default Root;
