import { useState, useEffect } from "react";
import ReversalPanel from "./ReversalPanel";
import ReversalPanelEmpty from "./ReversalPanelEmpty";
import { useIntl, IntlShape } from "react-intl";
import { sendMissingLocalization } from "@riotgames/riotpay-utils";
import { RfsApiInterface } from "../../types/api";
import { RepaymentData } from "../../types/reversals";

const validReversalStatesForRepayment = ["OPEN", "ENQUEUED", "ERROR"];

type ReversalPanelContainerProps = {
  oauthbearertoken: string;
  rfsApi: RfsApiInterface;
  locale: string;
  rfsEdgeHosts: Record<string, string>;
};

const ReversalPanelContainer = ({
  oauthbearertoken,
  rfsApi,
  locale,
  rfsEdgeHosts
}: ReversalPanelContainerProps): JSX.Element => {
  const [allReversals, setAllReversals] = useState<Record<string, RepaymentData>>({});
  const [isStartRepaymentButtonDisabled, setIsStartRepaymentButtonDisabled] = useState<Record<string, boolean>>({});
  const [isLoading, setIsLoading] = useState(true);

  const intl: IntlShape = useIntl();

  useEffect(() => {
    // Populate reversals from rfs-Edge
    const getReversals = (rfsEdgeEnv: string, rfsEdgeHost: string, allReversals: Record<string, RepaymentData>) => {
      const currentReversal: RepaymentData = { reversals: [], summary: { openCount: 0 }, apiError: false }; // get starting state
      return rfsApi
        .fetchReversals(oauthbearertoken, rfsEdgeHost)
        .then((resp) => {
          if (resp) {
            currentReversal.reversals = resp.reversals;
            currentReversal.summary = resp.summary;
            currentReversal.summary.owedAmount = resp?.summary?.owedAmount;
          }
        })
        .catch(() => {
          currentReversal.apiError = true;
          currentReversal.reversals = [];
          currentReversal.summary = { openCount: 0 };
        })
        .then(() => {
          Promise.resolve((allReversals[rfsEdgeEnv] = currentReversal));
        })
        .finally(() => {
          if (currentReversal.reversals.length > 0) {
            sendMissingLocalization(intl, currentReversal.reversals);
          }
        });
    };

    const getAllReversals = () => {
      const allReversals: Record<string, RepaymentData> = {};
      const allPromises: Array<Promise<void>> = [];
      const isStartRepaymentButtonDisabled: Record<string, boolean> = {};
      Object.entries(rfsEdgeHosts).forEach(([rfsEnv, rfsUrl]) => {
        const currentReversal = getReversals(rfsEnv, rfsUrl, allReversals);
        allPromises.push(currentReversal);
        isStartRepaymentButtonDisabled[rfsEnv] = false;
      });
      Promise.all(allPromises)
        .then(() => {
          setAllReversals(allReversals);
          setIsStartRepaymentButtonDisabled(isStartRepaymentButtonDisabled);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Couldn't get all reversals.");
          console.error(error);
        });
    };

    getAllReversals();
  }, [rfsEdgeHosts, intl, oauthbearertoken, rfsApi]);

  // Get a start URL
  const getStartUrl = (rfsEdgeEnv: string, rfsEdgeHost: string): Promise<string> => {
    return new Promise((resolve, reject) => {
      if (allReversals[rfsEdgeEnv].summary.openCount > 0) {
        rfsApi
          .getStartUrl(oauthbearertoken, locale, rfsEdgeHost)
          .then((data) => {
            if (data && data.startUrl) {
              resolve(data.startUrl);
            } else {
              setAllReversals((prevReversals) => ({
                ...prevReversals,
                [rfsEdgeEnv]: { ...prevReversals[rfsEdgeEnv], apiError: true }
              }));
              reject("rfs-edge didn't send a response.");
            }
          })
          .catch((error) => {
            reject(`Problem making request to rfs-edge: ${error}`);
          });
      } else {
        reject("No open reversals.");
      }
    });
  };

  const handleStartRepaymentClick = (rfsEdgeEnv: string, rfsEdgeHost: string) => {
    // Prevent double clicking
    setIsStartRepaymentButtonDisabled({ ...isStartRepaymentButtonDisabled, [rfsEdgeEnv]: true });
    getStartUrl(rfsEdgeEnv, rfsEdgeHost)
      .then((startUrl) => {
        window.location.href = startUrl;
      })
      .catch((error) => {
        console.error("Couldn't get repayment URL.");
        console.error(error);
        setIsStartRepaymentButtonDisabled({ ...isStartRepaymentButtonDisabled, [rfsEdgeEnv]: false });
      });
  };

  const displayReversalPanels = () => {
    let totalOwed = 0;
    Object.values(allReversals).forEach((panel) => {
      totalOwed += parseInt(panel.summary.owedAmount);
    });
    if (totalOwed === 0 && !isLoading) {
      return <ReversalPanelEmpty />;
    } else if (!isLoading) {
      return Object.entries(allReversals).map(([rfsEnv, repaymentData]) => (
        <ReversalPanel
          key={rfsEnv}
          rfsEdgeEnv={rfsEnv}
          rfsEdgeHost={rfsEdgeHosts[rfsEnv]}
          openReversals={repaymentData.reversals.filter(
            (r) => validReversalStatesForRepayment.includes(r.status) && !r.repaymentPublicFacingId
          )}
          pendingReversals={repaymentData.reversals.filter(
            (r) => validReversalStatesForRepayment.includes(r.status) && r.repaymentPublicFacingId
          )}
          summary={repaymentData.summary}
          handleStartRepaymentClick={handleStartRepaymentClick}
          startRepaymentButtonDisabled={isStartRepaymentButtonDisabled[rfsEnv]}
          apiError={repaymentData.apiError}
        />
      ));
    } else {
      return <div></div>;
    }
  };

  return <div>{displayReversalPanels()}</div>;
};

export default ReversalPanelContainer;
