import { FormattedMessage } from "react-intl";
import { resolveLocale } from "@riotgames/riotpay-l18n";

const InformationPanel = (): JSX.Element => {
  const locale = resolveLocale().replace("-", "_");

  return (
    <div className="row">
      <div className="card info-card">
        <div className="card-header">
          <FormattedMessage id="info-title" />
        </div>
        <div className="card-body">
          <p>
            <FormattedMessage id="info-text-chargebacks-left" />
          </p>
          <p>
            <FormattedMessage id="additional-info-text-left" />
            <a href="https://support-leagueoflegends.riotgames.com/hc/articles/4403733573011">
              <FormattedMessage id="info-ticket-link-1" />
            </a>
            <FormattedMessage id="additional-info-text-right" />
          </p>
          <p>
            <FormattedMessage
              id="valorant-chargeback-text"
              values={{
                here: (
                  <a href="https://support-leagueoflegends.riotgames.com/hc/en-us/articles/4403733573011-Chargebacks">
                    {locale === "tr_TR" ? ( // Adds specific "here" word translation for Turkish due to Turkish contextual linguistics
                      <FormattedMessage id="valorant-chargeback-text-here" />
                    ) : (
                      // Re-using the "here" word translation as used above for all other languages
                      <FormattedMessage id="info-ticket-link-1" />
                    )}
                  </a>
                )
              }}
            />
          </p>
        </div>
      </div>
    </div>
  );
};

export default InformationPanel;
