import { StrictMode } from "react";
import * as Sentry from "@sentry/browser";
import ReactDOM from "react-dom";
import "./static/css/index.css";
import { loadTranslations, resolveLocale } from "@riotgames/riotpay-l18n";

import ContainerRoot from "./containers/Root";

const rfsClientVersion = process.env.REACT_APP_RFS_VERSION;

const locale = resolveLocale().replace("_", "-");
const translations = loadTranslations(locale);
document.documentElement.lang = locale.slice(0, 2); // set 'lang' attribute on html tag

const clientEnvironment = getClientEnvironment();
if (clientEnvironment !== "local") {
  Sentry.init({
    dsn: "https://53c469245a2b4316be0545de7e5bf833@sentry.io/1384479",
    environment: clientEnvironment,
    release: `rfs-client@${rfsClientVersion}`,
    attachStacktrace: true
  });
}

ReactDOM.render(
  <StrictMode>
    <ContainerRoot locale={locale} messages={translations} />
  </StrictMode>,
  document.getElementById("root")
);

function getClientEnvironment() {
  const localMatch = window.location.href.indexOf("localhost") > -1;
  const stageMatch = window.location.href.indexOf("web.stage.rfs.pay.riotgames.com") > -1;
  const preprodMatch = window.location.href.indexOf("web.pre.rfs.pay.riotgames.com") > -1;
  if (stageMatch) {
    return "stage";
  } else if (localMatch) {
    return "local";
  } else if (preprodMatch) {
    return "preprod";
  } else {
    return "prod";
  }
}
