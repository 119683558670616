import PropTypes from "prop-types";
import { FormattedDate, FormattedMessage } from "react-intl";
import { extractGameCode, extractPlatformCode } from "@riotgames/riotpay-utils";
import { Computer, Smartphone, SportsEsports } from "@material-ui/icons";

import iconMap from "../../iconMap.json";

type ReversalDetailProps = {
  amount: string;
  currency: string;
  originalPaymentDatetime: string;
  chargeBackDatetime: string;
  originalStorefrontAccountCode: string;
  originalPaymentId: string;
  repaymentId?: string;
};

const ReversalDetail = ({
  amount,
  currency,
  originalPaymentDatetime,
  chargeBackDatetime,
  originalStorefrontAccountCode,
  originalPaymentId,
  repaymentId
}: ReversalDetailProps): JSX.Element => {
  const gameIconMap = (storeFrontCode: string) => {
    const gameCode = extractGameCode(storeFrontCode);
    if (iconMap[gameCode]) {
      return <img className="reversal-panel__icon--game" src={`/images/${iconMap[gameCode]}`} alt={gameCode} />;
    }
    return <FormattedMessage id={`game-title-${gameCode}`} defaultMessage={gameCode} />;
  };

  const platformIconMap = (storeFrontCode: string) => {
    const platformCode = extractPlatformCode(storeFrontCode);
    switch (platformCode) {
      case "mobile":
        return <Smartphone className="reversal-panel__icon--platform" />;
      case "console":
        return <SportsEsports className="reversal-panel__icon--platform" />;
      default:
        return <Computer className="reversal-panel__icon--platform" />;
    }
  };

  if (repaymentId) {
    return (
      <tr className="reversal-row__strike">
        <td className="reversal-panel-column__game">{gameIconMap(originalStorefrontAccountCode)}</td>
        <td className="reversal-panel-column__platform">{platformIconMap(originalStorefrontAccountCode)}</td>
        <td>{originalPaymentId}</td>
        <td className="reversal-panel-column__original">
          <FormattedDate value={originalPaymentDatetime} year="numeric" month="short" day="2-digit" />
        </td>
        <td className="reversal-panel-column__chargeback">
          <FormattedDate value={chargeBackDatetime} year="numeric" month="short" day="2-digit" />
        </td>
        <td className="list-number">
          {amount}&nbsp;{currency}
        </td>
      </tr>
    );
  }

  return (
    <tr className="reversal-panel-table__row">
      <td className="reversal-panel-column__game">{gameIconMap(originalStorefrontAccountCode)}</td>
      <td className="reversal-panel-column__platform">{platformIconMap(originalStorefrontAccountCode)}</td>
      <td>{originalPaymentId}</td>
      <td className="reversal-panel-column__original">
        <FormattedDate value={originalPaymentDatetime} year="numeric" month="short" day="2-digit" />
      </td>
      <td className="reversal-panel-column__chargeback">
        <FormattedDate value={chargeBackDatetime} year="numeric" month="short" day="2-digit" />
      </td>
      <td className="list-number">
        {amount}&nbsp;{currency}
      </td>
    </tr>
  );
};

ReversalDetail.propTypes = {
  amount: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  originalPaymentDatetime: PropTypes.string.isRequired,
  originalPaymentId: PropTypes.string.isRequired,
  repaymentId: PropTypes.string
};

export default ReversalDetail;
