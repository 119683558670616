import PropTypes from "prop-types";
import ReversalSummary from "./ReversalSummary";
import { FormattedMessage } from "react-intl";
import ReversalDetail from "./ReversalDetail";
import { Reversal, Summary } from "../../types/reversals";

type ReversalPanelProps = {
  rfsEdgeEnv: string;
  rfsEdgeHost: string;
  openReversals: Array<Reversal>;
  pendingReversals: Array<Reversal>;
  summary: Summary;
  apiError: boolean;
  handleStartRepaymentClick: (rfsEdgeEnv: string, rfsEdgeHost: string) => void;
  startRepaymentButtonDisabled: boolean;
};

const ReversalPanel = ({
  rfsEdgeEnv,
  rfsEdgeHost,
  openReversals,
  pendingReversals,
  summary,
  apiError,
  handleStartRepaymentClick,
  startRepaymentButtonDisabled
}: ReversalPanelProps): JSX.Element => {
  const openOnly = openReversals.map((r, i) => (
    <ReversalDetail
      key={i}
      originalPaymentId={r.originalPaymentId}
      amount={r.originalPaymentAmount}
      currency={r.originalPaymentCurrency}
      originalPaymentDatetime={r.originalPaymentDatetime}
      chargeBackDatetime={r.createdAt}
      originalStorefrontAccountCode={r.originalStorefrontAccountCode}
    />
  ));
  const pendingOnly = pendingReversals.map((r, i) => (
    <ReversalDetail
      key={i}
      originalPaymentId={r.originalPaymentId}
      amount={r.originalPaymentAmount}
      currency={r.originalPaymentCurrency}
      originalPaymentDatetime={r.originalPaymentDatetime}
      chargeBackDatetime={r.createdAt}
      originalStorefrontAccountCode={r.originalStorefrontAccountCode}
      repaymentId={r.repaymentPublicFacingId}
    />
  ));
  const errorContent = (
    <div className="alert alert-danger reversal-panel-error">
      <FormattedMessage
        id="flash-error-msg-left"
        defaultMessage="An error occurred, please try again. If the issue persists, please contact player support "
      />
      <a href="http://support.riotgames.com/hc/requests/new">
        <FormattedMessage id="info-ticket-link-1" defaultMessage="here" />
      </a>
      <FormattedMessage id="flash-error-msg-right" defaultMessage="." />
    </div>
  );
  const reversalsTable = (
    <table className="reversal-panel-table">
      <thead className="reversal-panel-table__head">
        <tr>
          <th className="reversal-panel-column__game">
            <FormattedMessage id="reversal-table-game" />
          </th>
          <th className="reversal-panel-column__platform">
            <FormattedMessage id="reversal-table-platform" />
          </th>
          <th>
            <FormattedMessage id="reversal-table-transaction" />
          </th>
          <th className="reversal-panel-column__original">
            <FormattedMessage id="reversal-table-transaction-date" />
          </th>
          <th className="reversal-panel-column__chargeback">
            <FormattedMessage id="reversal-table-chargeback-date" />
          </th>
          <th className="list-number">
            <FormattedMessage id="reversal-table-amount" />
          </th>
        </tr>
      </thead>
      <tbody>{openOnly.concat(pendingOnly)}</tbody>
    </table>
  );

  const displayReversalContent = () => {
    if (apiError) {
      return errorContent;
    } else if (openReversals.length > 0) {
      return reversalsTable;
    } else {
      return <div></div>;
    }
  };

  return (
    <div>
      <div className="reversal-panel">
        {(openReversals.length > 0 || apiError) && <div className="reversal-panel-header">{rfsEdgeEnv}</div>}
        {displayReversalContent()}
      </div>
      {openReversals.length > 0 && !apiError && (
        <div className="reversal-panel-footer">
          <ReversalSummary owedAmount={summary.owedAmount} owedCurrency={summary.owedCurrency} />
          <button
            onClick={() => handleStartRepaymentClick(rfsEdgeEnv, rfsEdgeHost)}
            className="btn btn-secondary reversal-panel-footer__button"
            disabled={startRepaymentButtonDisabled || openReversals.length === 0}
          >
            <FormattedMessage id="start-repayment-button" />
          </button>
        </div>
      )}
    </div>
  );
};

ReversalPanel.propTypes = {
  apiError: PropTypes.bool,
  openReversals: PropTypes.array.isRequired,
  pendingReversals: PropTypes.array.isRequired,
  summary: PropTypes.object.isRequired
};

export default ReversalPanel;
