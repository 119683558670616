import axios from "axios";
import { RfsApiInterface } from "../types/api";

class RfsApi implements RfsApiInterface {
  fetchReversals(oauthbearertoken: string, rfsEdgeHost: string): Promise<Response> {
    const options = {
      headers: {
        Authorization: "Bearer " + oauthbearertoken,
        Accept: "application/json"
      }
    };
    const uri = `${rfsEdgeHost}/rfs/v1/reversals/reversals`;
    return axios.get(uri, options).then((resp) => resp.data);
  }

  getStartUrl(oauthbearertoken: string, locale: string, rfsEdgeHost: string): Promise<Response> {
    const options = {
      headers: {
        Authorization: "Bearer " + oauthbearertoken,
        Accept: "application/json"
      }
    };
    const uri = `${rfsEdgeHost}/rfs/v1/repayments/repayments?locale=${locale}`;
    return axios
      .post(uri, {}, options) // POST with empty body is required by RFS
      .then((resp) => resp.data);
  }
}

export default RfsApi;
