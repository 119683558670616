import ReversalPanelContainer from "./ReversalPanelContainer";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { RfsApiInterface } from "../../types/api";

type ReversalInstanceContainerProps = {
  oauthbearertoken: string;
  locale: string;
  rfsApi: RfsApiInterface;
};

const ReversalInstanceContainer = ({
  oauthbearertoken,
  rfsApi,
  locale
}: ReversalInstanceContainerProps): JSX.Element => {
  const rfsEdgeEnvs = process.env.REACT_APP_RFS_EDGE_ENVS.split(",");
  const rfsEdgeHosts = process.env.REACT_APP_RFS_EDGE_HOSTS.split(",");
  const rfsEnvToHost = rfsEdgeEnvs.reduce((hosts, env, i) => ({ ...hosts, [env]: rfsEdgeHosts[i] }), {});

  return (
    <div>
      <div className="reversal-instances">
        <h1 className="reversal-instances__header">
          <FormattedMessage id="reversal-header" />
        </h1>
        <div className="reversal-instances-subheader">
          <p className="reversal-instances-subheader__text">
            <FormattedMessage id="reversal-subheader" />
          </p>
          <p className="reversal-instances-subheader__text">
            <FormattedMessage id="repayment-help-text-left" />
            <a href="https://support.riotgames.com/hc/articles/201753334-Chargebacks">
              <FormattedMessage id="info-ticket-link-2" defaultMessage="Player Support" />
            </a>
            <FormattedMessage id="repayment-help-text-right" />
          </p>
        </div>
      </div>
      {rfsEdgeHosts && (
        <ReversalPanelContainer
          oauthbearertoken={oauthbearertoken}
          rfsApi={rfsApi}
          locale={locale}
          rfsEdgeHosts={rfsEnvToHost}
        />
      )}
    </div>
  );
};

ReversalInstanceContainer.propTypes = {
  rfsApi: PropTypes.object.isRequired,
  oauthbearertoken: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired
};

export default ReversalInstanceContainer;
