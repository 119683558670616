import { FormattedMessage } from "react-intl";

const ReversalPanelEmpty = (): JSX.Element => {
  return (
    <div className="reversal-panel">
      <div className="alert alert-danger reversal-panel-error">
        <FormattedMessage id="no-chargebacks" />
        <div>
          <FormattedMessage id="no-balance-help-text-left" />
          <a href="http://support.riotgames.com/hc/requests/new">
            <FormattedMessage id="info-ticket-link-1" />
          </a>
          <FormattedMessage id="no-balance-help-text-right" />
        </div>
      </div>
    </div>
  );
};

export default ReversalPanelEmpty;
