import { FormattedMessage, FormattedNumber } from "react-intl";

type ReversalSummaryProps = {
  owedAmount?: string;
  owedCurrency?: string;
};

const ReversalSummary = ({ owedAmount, owedCurrency }: ReversalSummaryProps): JSX.Element => (
  <span className="reversal-panel-footer__text">
    <FormattedMessage id="total-owed" />
    {owedAmount && owedCurrency ? (
      <FormattedNumber value={parseFloat(owedAmount)} currency={owedCurrency} style="currency" />
    ) : (
      "N/A"
    )}
  </span>
);

export default ReversalSummary;
