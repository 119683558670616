import { useState, useEffect } from "react";
import { setRiotBarAuthState } from "@riotgames/riotpay-utils";
import { useRSOAuth } from "@riotgames/riotpay-utils";
import RiotBar from "@riotgames/riotbar-react";
import ReversalInstanceContainer from "../components/reversals/ReversalInstanceContainer";
import InformationPanel from "../components/information/InformationPanel";
import RfsApi from "../api/rfsApi";
import { useIntl } from "react-intl";

// Api will replace string if it contains "{}" with instance, otherwise will leave it along
const rfsApi = new RfsApi();

const App = (): JSX.Element => {
  const [riotBarLoaded, setRiotBarLoaded] = useState<boolean>(false);
  const [oauthbearertoken, setOauthbearertoken] = useState<string>();
  const { rsoReady, session, user: { gameName } = {}, hookLoginFunctions } = useRSOAuth();
  const intl = useIntl();

  useEffect(() => {
    if (window.navigator.userAgent.indexOf("MSIE ") > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./)) {
      alert("This site does not support Internet Explorer. Please use a different browser.");
    }
  }, []);

  useEffect(() => {
    if (!riotBarLoaded || !rsoReady) {
      return;
    }
    hookLoginFunctions();
    if (session) {
      setOauthbearertoken(session.accessToken);
      setTimeout(() => {
        setRiotBarAuthState(!!session, gameName);
      }, 250);
    }
  }, [riotBarLoaded, rsoReady, session, gameName, hookLoginFunctions]);

  const fixedLocale = intl.locale.replace("-", "_");

  return (
    <div className="d-flex flex-column flex-wrap">
      <RiotBar
        product="riotrepayment"
        environment={process.env.REACT_APP_RIOTBAR_ENV}
        locale={fixedLocale}
        onLoad={() => setRiotBarLoaded(true)}
      />
      <div className="container justify-content-center align-items-center">
        <div className="row header mt-5 pt-5" />
        {oauthbearertoken == null ? (
          <div>
            <InformationPanel />
          </div>
        ) : (
          <ReversalInstanceContainer oauthbearertoken={oauthbearertoken} rfsApi={rfsApi} locale={fixedLocale} />
        )}
      </div>
      <footer id="riotbar-footer-container" className="riotbar-footer" />
    </div>
  );
};

export default App;
